import React from 'react';

function ThankYou() {
    return (
        <div className="flex flex-col items-center justify-center text-center max-w-80 mx-auto pt-20">
            <p className="text-white text-lg">
                Thank you for signing up to my mailing list! I'm excited to be able to share my musical journey with you!
                If you get my emails in your spam folder, please right-click and select "Not Spam" or drag my email to your
                inbox folder so that future emails don't go to spam. Talk to you soon!
            </p>
            <a href="/" className="bg-white font-semibold text-black p-1 mb-8 mt-8 inline-block">HOME</a>
        </div>
    );
}

export default ThankYou;
