import React from 'react';

function Bookings() {
    return (
        <div className="flex flex-col items-center">
            <img src="/images/ARPITASHOOT-258.jpg" alt="Description of image" className="mt-16 mb-4 h-80" />
            <h1 className="text-3xl font-semibold text-center mt-16 mb-8 text-white">Bookings:</h1>
            <h1 className="text-white text-xl">theekaransingh@gmail.com</h1>
            {/* Add your content for the bookings page here */}
        </div>
    );
}

export default Bookings;
