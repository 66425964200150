// src/components/Button.js

import React from 'react';

function Button({ text, onClick, href, className = '', type = 'button' }) {
    const baseStyles = "bg-gray-900 text-white px-4 py-2 rounded-full font-semibold hover:bg-gray-800 inline-block";

    if (href) {
        return (
            <a href={href} className={`${baseStyles} ${className}`} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
    }

    return (
        <button type={type} onClick={onClick} className={`${baseStyles} ${className}`}>
            {text}
        </button>
    );
}

export default Button;
