import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';

function EditSong() {
    const navigate = useNavigate();
    const location = useLocation();
    const songData = location.state?.song;

    const [songName, setSongName] = useState(songData?.songName || '');
    const [links, setLinks] = useState(songData?.links || {
        musicVideoLink: '',
        spotifyLink: '',
        appleMusicLink: '',
        amazonMusicLink: '',
        youtubeMusicLink: '',
        pandoraLink: '',
        itunesStoreLink: '',
        tidalLink: '',
        soundcloudLink: '',
        deezerLink: '',
        iHeartRadioLink: '',
        qobuzLink: ''
    });
    const [coverArt, setCoverArt] = useState(songData?.coverArt || null);
    const [coverArtFile, setCoverArtFile] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(songData?.backgroundImage || null);
    const [backgroundImageFile, setBackgroundImageFile] = useState(null);
    const [userId, setUserId] = useState(null);

    const auth = getAuth();
    const storage = getStorage();

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            setUserId(user.uid);
        }
    }, [auth]);

    const handleImageChange = (event, setImage, setFile) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            setImage(URL.createObjectURL(img));
            setFile(img);
        }
    };

    const handleImageClick = (inputId) => {
        document.getElementById(inputId).click();
    };

    const openLink = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const handleLinkChange = (event, key) => {
        setLinks((prevLinks) => ({ ...prevLinks, [key]: event.target.value }));
    };

    const updateSong = async () => {
        if (!userId || !songData?.id) {
            console.error('User is not authenticated or song ID is missing!');
            return;
        }

        let updatedCoverUrl = coverArt;
        let updatedBackgroundUrl = backgroundImage;

        if (coverArtFile) {
            const coverArtPath = `songs/${coverArtFile.name}`;
            const coverArtRef = ref(storage, coverArtPath);
            const coverSnapshot = await uploadBytes(coverArtRef, coverArtFile);
            updatedCoverUrl = await getDownloadURL(coverSnapshot.ref);
        }

        if (backgroundImageFile) {
            const backgroundPath = `songs/${backgroundImageFile.name}`;
            const backgroundRef = ref(storage, backgroundPath);
            const backgroundSnapshot = await uploadBytes(backgroundRef, backgroundImageFile);
            updatedBackgroundUrl = await getDownloadURL(backgroundSnapshot.ref);
        }

        const songRef = doc(db, 'songs', songData.id);

        try {
            await updateDoc(songRef, {
                songName,
                links,
                coverArt: updatedCoverUrl,
                backgroundImage: updatedBackgroundUrl
            });
            console.log('Document updated with ID: ', songData.id);
            navigate('/catalog');
        } catch (e) {
            console.error('Error updating document: ', e);
        }
    };

    return (
        <div className="flex justify-center flex-col items-center mx-auto p-16 bg-gray-200" 
             style={{ background: backgroundImage ? `url(${backgroundImage}) no-repeat center center / cover` : undefined }}>
            <h1 className="text-black text-2xl mb-4">Edit song:</h1>

            {/* Render the cover art using <img> tag */}
            {coverArt ? (
                <img
                    src={coverArt}
                    alt="Cover Art"
                    className="h-60 w-60 mb-4 rounded-lg cursor-pointer"
                    onClick={() => handleImageClick('coverInput')}
                />
            ) : (
                <div className="h-60 w-60 mb-4 flex items-center justify-center bg-gray-300 rounded-lg cursor-pointer" onClick={() => handleImageClick('coverInput')}>
                    + Add Cover Art
                </div>
            )}

            <input
                id="coverInput"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setCoverArt, setCoverArtFile)}
                style={{ display: 'none' }}
            />

            <button className="mb-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => handleImageClick('backgroundInput')}>
                {backgroundImage ? "Edit Background" : "+ Add Background"}
            </button>
            <input
                id="backgroundInput"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setBackgroundImage, setBackgroundImageFile)}
                style={{ display: 'none' }}
            />

            <div className="flex flex-col gap-2 w-full max-w-md">
                <input
                    type="text"
                    placeholder="Song Name"
                    value={songName}
                    onChange={(e) => setSongName(e.target.value)}
                    className="p-2 rounded outline-none w-full"
                />
                {[
                    { key: 'musicVideoLink', placeholder: "Music Video Link" },
                    { key: 'spotifyLink', placeholder: "Spotify Link" },
                    { key: 'appleMusicLink', placeholder: "Apple Music Link" },
                    { key: 'amazonMusicLink', placeholder: "Amazon Music Link" },
                    { key: 'youtubeMusicLink', placeholder: "YouTube Music Link" },
                    { key: 'pandoraLink', placeholder: "Pandora Link" },
                    { key: 'itunesStoreLink', placeholder: "iTunes Store Link" },
                    { key: 'tidalLink', placeholder: "Tidal Link" },
                    { key: 'soundcloudLink', placeholder: "SoundCloud Link" },
                    { key: 'deezerLink', placeholder: "Deezer Link" },
                    { key: 'iHeartRadioLink', placeholder: "iHeartRadio Link" },
                    { key: 'qobuzLink', placeholder: "Qobuz Link" }
                ].map((input, index) => (
                    <div key={index} className="flex flex-row gap-2">
                        <input
                            type="url"
                            placeholder={input.placeholder}
                            value={links[input.key]}
                            onChange={(e) => handleLinkChange(e, input.key)}
                            className="p-2 rounded outline-none flex-grow"
                        />
                        <button
                            className="flex justify-center items-center bg-white text-black p-2 rounded hover:bg-gray-200"
                            onClick={() => openLink(links[input.key])}
                            disabled={!links[input.key]}
                        >
                            <FontAwesomeIcon icon={faLink} />
                        </button>
                    </div>
                ))}
            </div>
            <button className="mt-4 mb-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={updateSong}>Save Changes</button>
        </div>
    );
}

export default EditSong;
