// src/components/SignIn.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';  // Correct the path based on your project structure
import { useNavigate } from 'react-router-dom';


function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();  // Initialize useNavigate

    const handleSignIn = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/catalog');  // Redirect to /add-song on successful login
        } catch (error) {
            setError("Invalid credentials. Please try again.");
        }
    };

    return (
        <div className="flex justify-center mx-auto pt-20">                                            
            
                <form onSubmit={handleSignIn} className="flex flex-col gap-2 w-80">
                    <label className="text-white">Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="text-black p-2 rounded font-semibold outline-none"
                    />
                    <label className="text-white">Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="text-black p-2 rounded font-semibold outline-none"
                    />
                    <button type="submit" className="text-white font-semibold bg-blue-500 hover:bg-blue-600 p-2 mt-2 rounded">Sign In</button>
                    {error && <p className="text-red-500 text-center">{error}</p>}
                </form>
       
        </div>
    );
}

export default SignIn;
