import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SocialIcons from './SocialIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function NavMenu() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        // Toggle the overflow style on the body
        if (isMobileMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        // Cleanup function to set overflow to visible when the component unmounts
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isMobileMenuOpen]);  // Effect dependencies list

    return (
        <nav className="max-w-screen-xl mx-auto p-6 bg-black text-white flex items-center justify-between">
            {/* Left side - "Bookings" and "Subscribe" visible only in non-mobile views */}
            <div className="flex-1 flex items-center justify-start">
                <div className="flex gap-4">
                    <RouterLink to="/bookings" className="text-xl font-semibold hidden md:block">Bookings</RouterLink>
                    <a href="#mailing-list" className="text-xl font-semibold hidden md:block">Subscribe</a>
                </div>
                
                {/* "ARPITA" visible and positioned based on screen size */}
                <RouterLink to="/" className="text-4xl font-bold md:mx-auto flex-1 md:flex-none">ARPITA</RouterLink>
            </div>

            {/* Right side - Social Icons always on the right in non-mobile views */}
            <div className="hidden md:flex items-center">
                <SocialIcons />
            </div>

            {/* Mobile Menu Toggle Button - only visible in mobile view */}
            <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="md:hidden">
                <FontAwesomeIcon icon={faBars} />
            </button>

            {/* Mobile Menu (hidden by default) */}
            {isMobileMenuOpen && (
                <div className="absolute top-0 left-0 w-full h-screen bg-black flex flex-col items-center justify-center z-50">
                    <button className="mb-16 text-white" onClick={() => setIsMobileMenuOpen(false)}>
                        <FontAwesomeIcon icon={faTimes} size="2x" />
                    </button>
                    <RouterLink to="/bookings" className="text-4xl font-bold mb-8" onClick={() => setIsMobileMenuOpen(false)}>BOOKINGS</RouterLink>
                    <a href="#mailing-list" className="text-4xl font-bold mb-16" onClick={() => setIsMobileMenuOpen(false)}>SUBSCRIBE</a>
                    <SocialIcons />
                </div>
            )}
        </nav>
    );
}

export default NavMenu;
