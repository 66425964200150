// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtRBzhY3H1dsAIV1lLYPFJ2xJO8TdVz7I",
  authDomain: "arpita-95c5c.firebaseapp.com",
  projectId: "arpita-95c5c",
  storageBucket: "arpita-95c5c.appspot.com",
  messagingSenderId: "482787363916",
  appId: "1:482787363916:web:9638b1dcf954c5a471b8e9",
  measurementId: "G-F770E22X7R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { db, auth, analytics };
