// src/components/ElectronicPressKit.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import LazyCarousel from './LazyCarousel';
import Gallery from './Gallery';

function ElectronicPressKit() {
    const [songs, setSongs] = useState([]);
    const [loading, setLoading] = useState(true);

    // Paths for carousel images
    const carouselImagePaths = Array.from({ length: 27 }, (_, i) => `/images/wallpapers/Arpita ${i + 1}.jpg`);
    
    // Combine gallery images from both folders
    const galleryImages = [
        ...Array.from({ length: 27 }, (_, i) => `/images/wallpapers/Arpita ${i + 1}.jpg`),
        ...Array.from({ length: 4 }, (_, i) => `/images/gallery/Arpita ${i + 1}.jpg`)
    ];

    useEffect(() => {
        const fetchSongs = async () => {
            try {
                const q = query(collection(db, 'songs'));
                const querySnapshot = await getDocs(q);
                const songsData = [];
                querySnapshot.forEach((doc) => {
                    const song = doc.data();
                    song.id = doc.id;
                    songsData.push(song);
                });
                songsData.sort((a, b) => b.createdOn - a.createdOn);
                setSongs(songsData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching songs: ', error);
                setLoading(false);
            }
        };

        fetchSongs();
    }, []);

    if (loading) {
        return <div className="text-white text-center py-10">Loading...</div>;
    }

    return (
        <div className="bg-black text-white min-h-screen">
            {/* Carousel Section */}
            <div className="w-full mb-10">
                <LazyCarousel imagePaths={carouselImagePaths} />
            </div>

            {/* Header */}
            <header className="text-center mb-12 px-4">
                <h1 className="text-3xl md:text-4xl font-bold mb-4 underline">Electronic Press Kit</h1>
                <p className="text-base md:text-lg font-light">Discover the story, music, and visuals of Arpita.</p>
            </header>

            {/* Biography Section */}
            <section className="max-w-4xl mx-auto text-center mb-16 px-4">
                <h2 className="text-2xl md:text-3xl font-semibold mb-6 underline">Biography</h2>
                <p className="text-sm md:text-lg font-light mb-6">
                    Arpita is a talented singer and songwriter originally from Detroit, MI, now making her mark in New York City. Blending R&B and Pop, her music reflects a wide range of influences.
                </p>
                <p className="text-sm md:text-lg font-light mb-6">
                    She began her musical journey at the age of five, learning the harmonium, an Indian instrument, and discovering her love for music early on. Growing up in a musically rich Punjabi household, Arpita was exposed to various musical styles and cultural influences. This diverse background has shaped her unique sound, all while drawing inspiration from artists like Katy Perry, Demi Lovato, and Beyoncé.  
                </p>
                <p className="text-sm md:text-lg font-light mb-6">
                    Her songs have also been featured on the BET+ show “Diarra from Detroit,” further highlighting her growing influence as a songwriter and artist. Now in New York, Arpita is ready to reach new audiences. Her latest single “Hesitate,” along with songs like “Wait On Me,” “Unspoken,” “Stay,” and “Priceless,” showcases her growth as an artist. With each performance, she aims to deliver memorable experiences and connect with her listeners.
                </p>
            </section>

            {/* Notable Performances Section */}
            <section className="max-w-4xl mx-auto text-center mb-16 px-4">
                <h2 className="text-2xl md:text-3xl font-semibold mb-6 underline">Notable Performances</h2>
                <ul className="text-sm md:text-lg font-light space-y-4">
                    {[
                        "Detroit Pistons National Anthem - 2023",
                        "Motor City Pride - 2024",
                        "Event at Eric Adams, New York Mayor, Residence - 2024",
                        "Grooves New York - 2024",
                        "Brooklyn Music Hall - 2024",
                        "Soho Grand Hotel - 2024",
                        "Negril Grill - 2024",
                        "Hudson Yards Street Festival - 2024",
                        "LilliStar Rooftop - 2024",
                        "The Sugar Bar - 2024",
                        "SANGARS Hall - 2024",
                        "Ribs and Soul Fest - 2024",
                        "Wall Street Ultra Detroit - 2023"
                    ].map((performance, index) => (
                        <li key={index} className="flex items-center justify-center">
                            <FontAwesomeIcon icon={faStar} className="text-white mr-2" /> 
                            <span>{performance}</span>
                        </li>
                    ))}
                </ul>
            </section>

            {/* Gallery Section */}
            <section className="max-w-5xl mx-auto text-center mb-16 px-4">
                <h2 className="text-2xl md:text-3xl font-semibold mb-6 underline">Gallery</h2>
                <Gallery images={galleryImages} />
            </section>

            {/* Featured Music Section */}
            {/* Featured Music Section */}
<section className="max-w-4xl mx-auto text-center mb-16 px-4">
    <h2 className="text-2xl md:text-3xl font-semibold mb-6 underline">Discography</h2>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
        {songs.map((song) => (
            <div key={song.id} className="flex flex-col items-center text-center">
                <div className="w-48 h-48 mb-4 rounded-lg overflow-hidden">
                    <img
                        src={song.coverArt}
                        alt={`${song.songName} Cover Art`}
                        className="w-full h-full object-contain rounded-lg"
                    />
                </div>
                <h3 className="text-lg md:text-2xl font-semibold mb-2">{song.songName}</h3>
                <Button
                    text="Watch / Listen"
                    href={`/${encodeURIComponent(song.songName)}`}
                    className="bg-gray-900 text-white"
                />
            </div>
        ))}
    </div>
</section>


            {/* Contact Section */}
            <section className="max-w-3xl mx-auto text-center mb-16 px-4">
                <h2 className="text-2xl md:text-3xl font-semibold mb-6 underline">Contact</h2>
                <p className="text-sm md:text-lg font-light mb-4">
                    For booking inquiries, press requests, or more information, please contact:
                </p>
                <p className="text-sm md:text-lg font-semibold mb-2">theekaransingh@gmail.com</p>
            </section>
        </div>
    );
}

export default ElectronicPressKit;
