// src/components/Gallery.js

import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

function Gallery({ images }) {
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const openLightbox = (index) => {
        setIndex(index);
        setIsOpen(true);
    };

    return (
        <div className="text-center">
            {/* Image Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {images.map((image, i) => (
                    <div
                        key={i}
                        className="relative overflow-hidden bg-gray-200 rounded-lg shadow-lg w-full h-48 cursor-pointer" // Sets the square container
                        onClick={() => openLightbox(i)}
                    >
                        <img
                            src={image}
                            alt={`Gallery Image ${i + 1}`}
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>

            {/* Lightbox for fullscreen images */}
            {isOpen && (
                <Lightbox
                    slides={images.map((src) => ({ src }))}
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    index={index}
                    setIndex={setIndex}
                />
            )}
        </div>
    );
}

export default Gallery;
