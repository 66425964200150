import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faApple, faSpotify, faFacebook, faSoundcloud } from '@fortawesome/free-brands-svg-icons';

function SocialIcons() {
    return (
        <div className="flex gap-4">
            <a href="https://www.instagram.com/arpitasmusic" target="_blank" rel="noopener noreferrer">
                <button className="text-xl">
                    <FontAwesomeIcon icon={faInstagram} />
                </button>
            </a>
            <a href="https://www.youtube.com/c/ArpitaSinghMusic" target="_blank" rel="noopener noreferrer">
                <button className="text-xl">
                    <FontAwesomeIcon icon={faYoutube} />
                </button>
            </a>
            <a href="https://music.apple.com/us/artist/arpita/1653283765" target="_blank" rel="noopener noreferrer">
                <button className="text-xl">
                    <FontAwesomeIcon icon={faApple} />
                </button>
            </a>
            <a href="https://open.spotify.com/artist/6X1ZOnfJtkNfTVMH3aYVS0" target="_blank" rel="noopener noreferrer">
                <button className="text-xl">
                    <FontAwesomeIcon icon={faSpotify} />
                </button>
            </a>
            <button className="text-xl">
                <FontAwesomeIcon icon={faFacebook} />
            </button>
            <button className="text-xl">
                <FontAwesomeIcon icon={faSoundcloud} />
            </button>
        </div>
    );
}

export default SocialIcons;
