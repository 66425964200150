// src/components/HomePage.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query } from 'firebase/firestore';
import Button from './Button';

function HomePage() {
    const [songs, setSongs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSongs = async () => {
            try {
                const q = query(collection(db, 'songs'));
                const querySnapshot = await getDocs(q);
                const songsData = [];
                querySnapshot.forEach((doc) => {
                    const song = doc.data();
                    song.id = doc.id;
                    songsData.push(song);
                });
                songsData.sort((a, b) => b.createdOn - a.createdOn);
                setSongs(songsData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching songs: ', error);
                setLoading(false);
            }
        };

        fetchSongs();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {songs.map((song, index) => (
                <div
                    key={index}
                    style={{ backgroundImage: `url(${song.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                >
                    <div className="p-6 max-w-screen-xl mx-auto">
                        <div className="flex flex-col items-center pt-28 pb-28 md:flex-row md:items-center md:gap-36 gap-8">
                            <img src={song.coverArt} alt="Cover Art" className="h-250 sm:h-500" />
                            <div className="text-center mt-4 md:mt-0">
                                <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">{song.songName.toUpperCase()}</h1>
                                <Button
                                    text="Watch / Listen"
                                    href={`/${encodeURIComponent(song.songName)}`}
                                    className="text-xl"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default HomePage;
