// src/components/LazyCarousel.js

import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function LazyCarousel({ imagePaths }) {
    const [carouselImages, setCarouselImages] = useState([]);

    // Shuffle images on load
    useEffect(() => {
        const shuffledImages = imagePaths.sort(() => Math.random() - 0.5);
        setCarouselImages(shuffledImages);
    }, [imagePaths]);

    return (
        <>
            <style>
                {`
                    /* Hides carousel indicators on mobile */
                    @media (max-width: 768px) {
                        .carousel .control-dots {
                            display: none;
                        }
                    }
                `}
            </style>
            <Carousel
                autoPlay
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                className="carousel-container"
            >
                {carouselImages.map((image, index) => (
                    <div key={index}>
                        <img
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-[35vh] md:h-[65vh] lg:h-[75vh] object-cover"
                            loading="lazy" // Lazy loading attribute for better performance
                        />
                    </div>
                ))}
            </Carousel>
        </>
    );
}

export default LazyCarousel;
