// src/components/FooterMenu.js

import React, { useState } from 'react';
import SocialIcons from './SocialIcons';
import Button from './Button';

function FooterMenu() {
    const [email, setEmail] = useState('');

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <footer className="max-w-screen-xl mx-auto pt-36 pb-16 bg-black text-white flex flex-col items-center">
            <div id="mc_embed_signup">
                <form
                    action="https://gmail.us8.list-manage.com/subscribe/post?u=6d98254ff8712ec9a2780d92c&amp;id=affa86945a&amp;f_id=006065e0f0"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate flex flex-col items-center mb-8 w-80"
                    target="_self">
                    <h1 id="mailing-list" className="text-3xl sm:text-4xl text-center mb-8 font-semibold">MAILING LIST:</h1>
                    <input
                        type="email"
                        value={email}
                        onChange={handleInputChange}
                        name="EMAIL"
                        className="required email bg-black text-white p-2 mb-8 border-white rounded-none border-b w-full outline-none text-xl"
                        required
                    />
                    <div className="clear">
                        <Button
                            type="submit"
                            text="Subscribe"
                            className="w-full text-center mb-6"
                        />
                    </div>
                    <p className="newsletter-desc text-sm font-normal">Sign up for regular updates from Arpita.</p>
                    <p className="newsletter-desc text-sm font-normal">You may withdraw at any time.</p>
                    <p className="newsletter-desc text-sm font-normal mb-16">
                        We do not sell your personal info.
                    </p>
                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                        <input type="text" name="b_6d98254ff8712ec9a2780d92c_affa86945a" tabIndex="-1" value="" />
                    </div>
                </form>
            </div>

            <SocialIcons />

            <Button
                text="Electronic Press Kit"
                href="/electronic-press-kit"
                className="mt-6 mb-4"
            />

            {/* Use Button component for the mgmt/karan link */}
            <Button
                text="mgmt/karan"
                href="https://www.karansingh.co"
                className="font-thin mb-6"
            />

            <h1 className="font-semibold mb-4 text-xs">&copy; 2024 ARPITA, LLC ALL RIGHTS RESERVED</h1>
            
            
        </footer>
    );
}

export default FooterMenu;
