import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

function SongPage() {
    const { songName } = useParams();
    const [song, setSong] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const decodedSongName = decodeURIComponent(songName);
        const fetchSong = async () => {
            try {
                const q = query(collection(db, 'songs'), where("songName", "==", decodedSongName));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const songData = querySnapshot.docs[0].data();
                    setSong(songData);
                } else {
                    setError('No such song found!');
                }
                setLoading(false);
            } catch (err) {
                console.error('Error fetching song:', err);
                setError('Error fetching song');
                setLoading(false);
            }
        };

        fetchSong();
    }, [songName]);

    if (loading) return <div className="text-center py-10 text-lg">Loading...</div>;
    if (error) return <div className="text-center text-red-500 text-lg py-10">{error}</div>;

    function renderServiceButton(icon, alt, link, buttonText = 'Play') {
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" className="w-full text-center">
                <div className="flex justify-between items-center hover:bg-gray-100 p-4 rounded-lg">
                    <img src={icon} alt={alt} className="w-28" />
                    <button className="bg-gray-200 p-2 rounded hover:bg-gray-300">{buttonText}</button>
                </div>
            </a>
        );
    }

    function renderServiceButtons(song) {
        const serviceButtons = [];

        // Add Music Video Link first if it exists
        if (song.links.musicVideoLink) {
            serviceButtons.push(renderServiceButton('/images/icons/youtube_icon.png', 'Music Video', song.links.musicVideoLink, 'Watch Video'));
        }

        // Add other service links
        if (song.links.spotifyLink) {
            serviceButtons.push(renderServiceButton('/images/icons/spotify.png', 'Spotify logo', song.links.spotifyLink));
        }
        if (song.links.appleMusicLink) {
            serviceButtons.push(renderServiceButton('/images/icons/apple.png', 'Apple Music logo', song.links.appleMusicLink));
        }
        if (song.links.amazonMusicLink) {
            serviceButtons.push(renderServiceButton('/images/icons/amazon.png', 'Amazon Music logo', song.links.amazonMusicLink));
        }
        if (song.links.youtubeMusicLink) {
            serviceButtons.push(renderServiceButton('/images/icons/youtube.png', 'YouTube Music logo', song.links.youtubeMusicLink));
        }
        if (song.links.pandoraLink) {
            serviceButtons.push(renderServiceButton('/images/icons/pandora.png', 'Pandora logo', song.links.pandoraLink));
        }
        if (song.links.itunesStoreLink) {
            serviceButtons.push(renderServiceButton('/images/icons/itunes.png', 'iTunes Store logo', song.links.itunesStoreLink, 'Download'));
        }
        if (song.links.tidalLink) {
            serviceButtons.push(renderServiceButton('/images/icons/tidal.png', 'Tidal logo', song.links.tidalLink));
        }
        if (song.links.soundcloudLink) {
            serviceButtons.push(renderServiceButton('/images/icons/soundcloud.png', 'SoundCloud logo', song.links.soundcloudLink));
        }
        if (song.links.deezerLink) {
            serviceButtons.push(renderServiceButton('/images/icons/deezer.png', 'Deezer logo', song.links.deezerLink));
        }
        if (song.links.iHeartRadioLink) {
            serviceButtons.push(renderServiceButton('/images/icons/iHeartRadio.png', 'iHeart Radio logo', song.links.iHeartRadioLink));
        }
        if (song.links.qobuzLink) {
            serviceButtons.push(renderServiceButton('/images/icons/qobuz.png', 'Qobuz logo', song.links.qobuzLink));
        }

        // Insert <hr> between service buttons if more than one button is rendered
        const serviceElements = serviceButtons.flatMap((button, index) => [
            button,
            index < serviceButtons.length - 1 && <hr key={`hr-${index}`} />,
        ]).filter(Boolean);

        return serviceElements;
    }

    return (
        <div className="p-5">
            {song && (
                <div className="max-w-md mx-auto flex flex-col items-center">
                    <img src={song.coverArt} alt={`${song.songName} cover art`} className="h-48 w-48 mb-4 rounded-lg" />
                    <h1 className="text-3xl font-bold text-white uppercase mb-4">{song.songName}</h1>
                    <p className="text-white mb-8">Choose music service</p>
                    <div className="bg-white rounded-lg w-80 flex flex-col">
                        {renderServiceButtons(song)}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SongPage;
