import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function Catalog() {
    const navigate = useNavigate();
    const [songName, setSongName] = useState('');
    const [links, setLinks] = useState({
        musicVideoLink: '', // Add Music Video Link here
        spotifyLink: '',
        appleMusicLink: '',
        amazonMusicLink: '',
        youtubeMusicLink: '',
        pandoraLink: '',
        itunesStoreLink: '',
        tidalLink: '',
        soundcloudLink: '',
        deezerLink: '',
        iHeartRadioLink: '',
        qobuzLink: ''
    });
    const [coverArt, setCoverArt] = useState(null);
    const [coverArtFile, setCoverArtFile] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [backgroundImageFile, setBackgroundImageFile] = useState(null);
    const [userId, setUserId] = useState(null);

    const auth = getAuth();
    const storage = getStorage();

    useEffect(() => {
        const user = auth.currentUser;
        if (user) {
            setUserId(user.uid);
        }
    }, [auth]);

    const handleImageChange = (event, setImage, setFile) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            setImage(URL.createObjectURL(img));
            setFile(img);
        }
    };

    const handleImageClick = (inputId) => {
        document.getElementById(inputId).click();
    };

    const openLink = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    const handleLinkChange = (event, key) => {
        setLinks((prevLinks) => ({ ...prevLinks, [key]: event.target.value }));
    };

    const saveSong = async () => {
        if (!userId) {
            console.error('No user logged in!');
            return;
        }

        const coverArtPath = `songs/${coverArtFile.name}`;
        const backgroundPath = `songs/${backgroundImageFile.name}`;

        const coverArtRef = ref(storage, coverArtPath);
        const backgroundRef = ref(storage, backgroundPath);

        try {
            const coverSnapshot = await uploadBytes(coverArtRef, coverArtFile);
            const coverUrl = await getDownloadURL(coverSnapshot.ref);

            const backgroundSnapshot = await uploadBytes(backgroundRef, backgroundImageFile);
            const backgroundUrl = await getDownloadURL(backgroundSnapshot.ref);

            const createdOn = new Date();

            const songData = {
                songName,
                links,  // Include all links including musicVideoLink
                coverArt: coverUrl,
                backgroundImage: backgroundUrl,
                createdOn
            };

            const docRef = await addDoc(collection(db, 'songs'), songData);
            console.log('Document written with ID: ', docRef.id);
            navigate('/catalog');
        } catch (e) {
            console.error('Error adding document: ', e);
        }
    };

    return (
        <div className={`flex justify-center flex-col items-center mx-auto p-16 ${backgroundImage ? '' : 'bg-gray-200'}`} 
             style={{ background: backgroundImage ? `url(${backgroundImage}) no-repeat center center / cover` : undefined }}>
            <h1 className="text-black text-2xl mb-4">Add a song:</h1>
            <div className="h-60 w-60 mb-4" style={{ backgroundImage: `url(${coverArt})`, backgroundSize: 'cover', backgroundPosition: 'center', cursor: 'pointer' }} onClick={() => handleImageClick('coverInput')}>
                {!coverArt && <div className="flex items-center justify-center h-full w-full bg-gray-300">+ Add Cover Art</div>}
            </div>
            <input
                id="coverInput"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setCoverArt, setCoverArtFile)}
                style={{ display: 'none' }}
            />
            <button className="mb-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => handleImageClick('backgroundInput')}>{backgroundImage ? "Edit Background" : "+ Add Background"}</button>
            <input
                id="backgroundInput"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setBackgroundImage, setBackgroundImageFile)}
                style={{ display: 'none' }}
            />
            <div className="flex flex-col gap-2 w-full max-w-md">
                <input
                    type="text"
                    placeholder="Song Name"
                    value={songName}
                    onChange={(e) => setSongName(e.target.value)}
                    className="p-2 rounded outline-none w-full"
                />
                {[
                    { key: 'musicVideoLink', placeholder: "Music Video Link" },
                    { key: 'spotifyLink', placeholder: "Spotify Link" },
                    { key: 'appleMusicLink', placeholder: "Apple Music Link" },
                    { key: 'amazonMusicLink', placeholder: "Amazon Music Link" },
                    { key: 'youtubeMusicLink', placeholder: "YouTube Music Link" },
                    { key: 'pandoraLink', placeholder: "Pandora Link" },
                    { key: 'itunesStoreLink', placeholder: "iTunes Store Link" },
                    { key: 'tidalLink', placeholder: "Tidal Link" },
                    { key: 'soundcloudLink', placeholder: "SoundCloud Link" },
                    { key: 'deezerLink', placeholder: "Deezer Link" },
                    { key: 'iHeartRadioLink', placeholder: "iHeartRadio Link" },
                    { key: 'qobuzLink', placeholder: "Qobuz Link" }
                ].map((input, index) => (
                    <div key={index} className="flex flex-row gap-2">
                        <input
                            type="url"
                            placeholder={input.placeholder}
                            value={links[input.key]}
                            onChange={(e) => handleLinkChange(e, input.key)}
                            className="p-2 rounded outline-none flex-grow"
                        />
                        <button
                            className="flex justify-center items-center bg-white text-black p-2 rounded hover:bg-gray-200"
                            onClick={() => openLink(links[input.key])}
                            disabled={!links[input.key]}
                        >
                            <FontAwesomeIcon icon={faLink} />
                        </button>
                    </div>
                ))}
            </div>
            <button className="mt-4 mb-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={saveSong}>Save Song</button>
        </div>
    );
}

export default Catalog;
